import React from "react";
import { Helmet } from "react-helmet";

const SiteMetaData = () => {
  const data = {
    title: "Oriental Takeaway",
    description:
      "Oriental is a chinese takeaway in Hackney, London. Find us at 444, kingsland Road, Dalston, London, E8 4AE or call 020 7254 2145 for in-house orders. For delivery visit https://www.oriental-takeaway.co.uk/ for more information.",
    keywords:
      "Oriental, chinese, dalston, hackney, london, delivery, food, takeaway",
    url: "https://www.oriental-takeaway.co.uk/",
  };
  return (
    <>
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={data.description} />
        <meta name="keywords" content={data.keywords} />
        <meta name="url" content={data.url} />
      </Helmet>
    </>
  );
};

export default SiteMetaData;
