import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navibar from "./navbar";
import Footer from "./footer";
import "./css/layout_main.css";
import SiteMetaData from "./metadata";

const Layout = ({ pageTitle, children }) => {
  return (
    <>
      <SiteMetaData />
      <main className="content_layout">
        <title>{pageTitle}</title>
        <Navibar />
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
