import React from "react";
import { Container } from "react-bootstrap";
import "../components/css/footer_css.css";

const Footer = () => {
  const date = new Date();

  return (
    <Container fluid="true" className="footer">
      <Container>
        <p>Copyright &copy; {date.getFullYear()}</p>
      </Container>
    </Container>
  );
};

export default Footer;
