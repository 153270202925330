import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import "./css/navbar.css";

const Navibar = () => {
  return (
    <div className="navigation">
      <Container>
        <Row className="main_navigation_bar">
          <Col
            lg={{ span: 1, offset: 8 }}
            sm={{ span: 3, offset: 0 }}
            xs={{ span: 3, offset: 0 }}
            className="main_navigation_block"
          >
            <Link to="/" className="main_navigation_links">
              Home
            </Link>
          </Col>
          <Col lg="1" sm="3" xs="3" className="main_navigation_block">
            <Link to="/menu" className="main_navigation_links">
              Menu
            </Link>
          </Col>
          <Col lg="1" sm="3" xs="3" className="main_navigation_block">
            <Link to="/about" className="main_navigation_links">
              Contact
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navibar;
